/* basic styles */

.skeletonproductimage {
  width: 150px;
  height: 160px;
  background: #ddd;
  border-radius: 4px;
  margin: 16px auto;
  margin-top: 16px;
}

.skeletontitle {
  width: 150px;
  height: 20px;
  margin: 0 auto 10px;
  background: #ddd;
  border-radius: 4px;
}
.skeletontext {
  background-color: #ddd;
  width: 40%;
  min-height: 16px;
  margin: 0 auto 10px;
  /* margin: 10px 0; */
  text-align: center;
  border-radius: 4px;
}

.mobileskeletonimage {
  width: 100px;
  height: 110px;
  background: #ddd;
  border-radius: 4px;
  margin: 16px 16px;
}

.mobileskeletontitle {
  width: 150px;
  height: 20px;
  margin: 10px auto 10px;
  background: #ddd;
  border-radius: 4px;
}

.mobileskeletontext {
  background-color: #ddd;
  width: 60%;
  min-height: 16px;
  margin: 0 0 10px;
  /* margin: 10px 0; */
  border-radius: 4px;
}

@media (max-width: 512px) {
  .skeletonproductimage {
    width: 100px;
    height: 100px;
  }
  .skeletontitle {
    width: 90px;
    margin: 0.5rem;
  }
  .skeletontext {
    width: 90px;
  }
}
